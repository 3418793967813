@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@font-face {
  font-family: 'Axiforma-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Axiforma-Light';
  font-weight: 1 1000;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Light.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Axiforma-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('https://anima-uploads.s3.amazonaws.com/5c59b0a881353e000dcaa8e8/Kastelov - Axiforma SemiBold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Axiforma-ExtraBold';
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/5c4753d5d7b7cc000a215345/Kastelov - Axiforma ExtraBold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Axiforma-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Axiforma-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('https://anima-uploads.s3.amazonaws.com/596db24aa0dc3c000d9a4ed2/Axiforma-Bold.ttf')
    format('truetype');
}

* {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

.axiforma-bold-blue-16px {
  color: rgba(37, 114, 232, 1);
  color: var(--blue);
  font-family: 'Axiforma-Bold', Helvetica, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.axiforma-bold-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Axiforma-Bold', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.axiforma-extra-bold-eerie-black-16px {
  color: rgba(29, 29, 26, 1);
  color: var(--eerie-black);
  font-family: 'Axiforma-ExtraBold', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.axiforma-extra-bold-eerie-black-20px {
  color: rgba(29, 29, 26, 1);
  color: var(--eerie-black);
  font-family: 'Axiforma-ExtraBold', Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.axiforma-medium-eerie-black-16px {
  color: rgba(29, 29, 26, 1);
  color: var(--eerie-black);
  font-family: 'Axiforma-Medium', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.axiforma-regular-normal-blue-16px {
  color: rgba(37, 114, 232, 1);
  color: var(--blue);
  font-family: 'Axiforma-Regular', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.axiforma-regular-normal-blue-14px {
  color: rgba(37, 114, 232, 1);
  color: var(--blue);
  font-family: 'Axiforma-Regular', Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.axiforma-regular-normal-eerie-black-16px {
  color: rgba(29, 29, 26, 1);
  color: var(--eerie-black);
  font-family: 'Axiforma-Regular', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.axiforma-regular-normal-eerie-black-12px {
  color: rgba(29, 29, 26, 1);
  color: var(--eerie-black);
  font-family: 'Axiforma-Regular', Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.axiforma-regular-normal-trout-16px {
  color: rgba(78, 81, 90, 1);
  color: var(--trout);
  font-family: 'Axiforma-Regular', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.axiforma-regular-normal-white-16px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Axiforma-Regular', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.toast-text {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: 'Axiforma-SemiBold', Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.axiforma-medium-blue-22px {
  color: rgba(37, 114, 232, 1);
  color: var(--blue);
  font-family: 'Axiforma-Medium', Helvetica, Arial, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}

.axiforma-light-blue-21px {
  color: rgba(37, 114, 232, 1);
  color: var(--blue);
  font-family: 'Axiforma-Light', Helvetica, Arial, serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}

.error-text {
  color: rgb(255, 75, 76);
  color: var(--error-red);
  font-family: 'Axiforma-Light', Helvetica, Arial, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}

.error-text-thin {
  color: rgb(255, 75, 76);
  color: var(--error-red);
  font-family: 'Axiforma-Regular', Helvetica, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.axiforma-regular-blue-semi-bold-14px {
  color: rgba(37, 114, 232, 1);
  color: var(--blue);
  font-family: 'Axiforma-SemiBold', Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.axiforma-regular-red-semi-bold-14px {
  color: rgb(255, 75, 76);
  color: var(--error-red);
  font-family: 'Axiforma-SemiBold', Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.axiforma-regular-black-regular-14px {
  color: black;
  font-family: 'Axiforma-Regular', Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.centered {
    -webkit-align-self: center;
            align-self: center;
    text-align: center;
}

.glass {
  background: rgba(255, 255, 255, 0.3);
  background: var(--firefox-white);
  border-radius: 45px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 20px var(--box-shadow);
  -ms-overflow-style: none; /* Hide scrollbars on IE and Edge */
  scrollbar-width: none; /* Hide scrollbars on Firefox */
}

.glass::-webkit-scrollbar {
  /* Hide scrollbars on Chrome, Safari and Opera */
  display: none;
}

@supports (
  ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em)))
) {
  .glass {
    -webkit-backdrop-filter: blur(15px) brightness(104%);
    backdrop-filter: blur(15px) brightness(104%);
    border-radius: 45px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 20px var(--box-shadow);
  }
}

.hidden {
  display: none;
}

.hidden-keep-space {
  visibility: hidden;
}

:root {
  --blue: rgba(37, 114, 232, 1);
  --caribbean-green: rgba(5, 227, 145, 1);
  --error-red: rgb(255, 75, 76);
  --box-shadow: rgba(0, 0, 0, 0.06);
  --transparent-white: rgba(255, 255, 255, 0.85);
  --transparent-blue: rgba(37, 114, 232, 0.15);
  --overlay-white: rgb(255, 255, 255);
  --firefox-white: rgba(255, 255, 255, 0.3);
  --half-blue: rgb(143, 168, 207);

  --blue-jeans: rgba(105, 165, 243, 1);
  --eucalyptus: rgba(64, 232, 176, 1);
  --eerie-black: rgba(29, 29, 26, 1);
  --scarpa-flow: rgba(86, 97, 97, 1);
  --trout: rgba(78, 81, 90, 1);
  --white: rgba(255, 255, 255, 1);
  --white-2: rgba(255, 255, 255, 0.54);
  --zircon: rgba(245, 247, 255, 1);

  --light: rgb(249, 251, 255);
  --shadow: rgb(227, 233, 245);
  --darker-shadow: rgb(210, 220, 241);
  --lighter-light: rgb(255, 255, 255);
  --background: rgb(238, 242, 251);
}

.exclusion-1 {
  -webkit-backdrop-filter: blur(25px) brightness(105%);
  backdrop-filter: blur(25px) brightness(105%);
  border-radius: 41px;
  height: 190px;
  left: 1109px;
  position: absolute;
  top: 878px;
  width: 280px;
}
.exclusion-2 {
  left: 785px;
}

.page-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.background-image{
  /* Full height */
  position: fixed;
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Preserve aspect ratio */
  min-width: 100%;
  min-height: 100%;
}

.side-bar {
  height: 90%;
  width: 15%;
  left: 5%;
  top: 5%;
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.side-bar-top {
  width: 100%;
  height: 75%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.logo {
  position: relative;
  margin: 20%;
  width: 30%;
}

.selected-page  {
  background-color: var(--blue);
  border-radius: 200px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.102);
  margin-bottom: 3%;
  padding: 5px;
  width: 75%;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.unselected-page  {
  margin-bottom: 3%;
  padding: 5px;
  width: 75%;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.page-icon {
  width: 10%;
  margin-right: 7%;
  margin-left: 7%;
}

.side-bar-bottom {
  width: 100%;
  height: 25%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.utils-container {
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 20%;
  margin-bottom: 5%;
}

.utils-icon {
  height: 7%;
  width: 7%;
  margin-right: 5%;
}

.widgets {
  left: 25%;
  top: 5%;
  width: 70%;
  height: 90%;
  display: -webkit-flex;
  display: flex;
  position: fixed;
}

.cheese-graph {
  height: 50%;
  width: 20%;
  margin-right: 3%;
  margin-bottom: 3%;
  padding: 3%;
}


.aug-2018 {
  height: 21px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 1px;
  width: 90px;
}
.august-2018 {
  color: rgba(49,57,77,1.0);
  font-family: 'Axiforma-SemiBold', Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 18px;
  left: 10.93%;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  top: 5.88%;
  white-space: nowrap;
  width: 33.66%;
}

.component-1-1 {
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.component-2-1 {
  bottom: calc(33.91% - 74px);
  height: 218px;
  left: calc(20.02% - 10px);
  position: absolute;
  width: 50px;
}
.component-3-1 {
  bottom: calc(36.92% - 87px);
  height: 234px;
  left: calc(28.46% - 14px);
  position: absolute;
  width: 50px;
}
.component-4-1 {
  bottom: calc(24.68% - 35px);
  height: 143px;
  left: calc(36.90% - 18px);
  position: absolute;
  width: 50px;
}
.component-5-1 {
  bottom: calc(40.06% - 100px);
  height: 249px;
  position: absolute;
  right: calc(36.90% - 18px);
  width: 50px;
}
.component-6-1 {
  bottom: calc(29.09% - 54px);
  height: 185px;
  position: absolute;
  right: calc(28.46% - 14px);
  width: 50px;
}
.component-7-1 {
  bottom: calc(25.42% - 38px);
  height: 151px;
  position: absolute;
  right: calc(20.02% - 10px);
  width: 50px;
}
.component-8-1 {
  height: 16px;
  left: calc(32.95% - 36px);
  position: absolute;
  top: calc(21.74% - 4px);
  width: 110px;
}
.date-range {
  height: 17px;
  position: absolute;
  right: 86px;
  top: calc(21.14% - 4px);
  width: 229px;
}
.dates {
  bottom: 0px;
  height: 19px;
  left: 0px;
  position: absolute;
  width: 100%;
}

.dec-2018 {
  height: 21px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: -2px;
  text-align: center;
  top: 1px;
  width: 90px;
}
.dfectueux-1 {
  height: 19px;
  left: -40.19%;
  letter-spacing: 0.00px;
  line-height: 16px;
  opacity: 0;
  position: absolute;
  top: 38px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  white-space: nowrap;
  width: 93px;
}
.dfectueux-2 {
  height: 19px;
  left: -41.81%;
  letter-spacing: 0.00px;
  line-height: 16px;
  opacity: 0;
  position: absolute;
  top: 38px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  white-space: nowrap;
  width: 93px;
}
.dfectueux2 {
  bottom: -2px;
  height: 19px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: 5px;
  white-space: nowrap;
  width: 93px;
}
.disponible-12 {
  bottom: 45px;
  height: 18px;
  left: -32.81%;
  letter-spacing: 0.00px;
  line-height: 16px;
  opacity: 0;
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  white-space: nowrap;
  width: 85px;
}
.disponible-22 {
  bottom: 45px;
  height: 18px;
  left: -33.19%;
  letter-spacing: 0.00px;
  line-height: 16px;
  opacity: 0;
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  white-space: nowrap;
  width: 85px;
}
.disponible2 {
  height: 18px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: 11px;
  top: 0px;
  white-space: nowrap;
  width: 85px;
}
.exclusion-3 {
  -webkit-backdrop-filter: blur(25px) brightness(105%);
  backdrop-filter: blur(25px) brightness(105%);
  border-radius: 41px;
  height: 301px;
  left: 785px;
  position: absolute;
  top: 1108px;
  width: 1397px;
}
.external-link-square2 {
  bottom: 121px;
  height: 14px;
  left: calc(20.07% - 3px);
  position: absolute;
  width: 14px;
}
.faq2 {
  bottom: 117px;
  height: 18px;
  left: calc(33.33% - 11px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  white-space: nowrap;
  width: 34px;
}
.from {
  height: 18px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  top: 5.88%;
  white-space: nowrap;
  width: 9.62%;
}
.graph {
  bottom: 47px;
  height: 259px;
  left: 129px;
  position: absolute;
  width: calc(100.00% - 216px);
}
.group- {
  height: 100%;
  position: relative;
}
.group-64 {
  background-size: 100% 100%;
  height: calc(100.00% + 903px);
  left: -18px;
  position: relative;
  top: -836px;
  width: calc(100.00% + 54px);
}
.group-78 {
  height: 10px;
  position: absolute;
  right: 1px;
  top: 2px;
  width: 14px;
}
.group-80 {
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.group-82 {
  height: 6px;
  position: absolute;
  right: 1px;
  top: 2px;
  width: 6px;
}
.group-84 {
  height: 2px;
  position: absolute;
  right: 1px;
  top: 0px;
  width: 12px;
}
.group-86 {
  height: 2px;
  left: 0px;
  position: absolute;
  top: 1px;
  width: 2px;
}
.group-88 {
  bottom: calc(49.98% - 1px);
  height: 2px;
  left: 1px;
  position: absolute;
  width: 2px;
}
.group-90 {
  bottom: 0px;
  height: 2px;
  left: 1px;
  position: absolute;
  width: 2px;
}
.group-92 {
  height: 2px;
  position: absolute;
  right: 0px;
  top: calc(49.97% - 1px);
  width: 13px;
}
.group-94 {
  bottom: 1px;
  height: 2px;
  left: calc(47.15% - 4px);
  position: absolute;
  width: 8px;
}
.group-96 {
  bottom: 39px;
  height: 68px;
  left: calc(50.00% - 68px);
  position: absolute;
  width: 137px;
}
.group-97 {
  -webkit-backdrop-filter: blur(25px) brightness(105%);
  backdrop-filter: blur(25px) brightness(105%);
  border-radius: 41px;
  height: 460px;
  left: 1189px;
  position: absolute;
  top: 148px;
  width: 993px;
}
.group-98 {
  -webkit-backdrop-filter: blur(25px) brightness(105%);
  backdrop-filter: blur(25px) brightness(105%);
  border-radius: 41px;
  height: 420px;
  left: 1433px;
  position: absolute;
  top: 648px;
  width: 749px;
}

.indisponible-12 {
  height: 18px;
  left: -46.00%;
  letter-spacing: 0.00px;
  line-height: 16px;
  opacity: 0;
  position: absolute;
  top: 40px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  white-space: nowrap;
  width: 98px;
}
.indisponible2 {
  height: 18px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: -2px;
  top: calc(50.00% - 9px);
  white-space: nowrap;
  width: 98px;
}
.inventaire2 {
  height: calc(100.00% + 2px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: -2px;
  top: 1px;
  white-space: nowrap;
  width: 81px;
}

.line-2 {
  height: 1px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.line-2-1 {
  height: 1px;
  left: 0px;
  position: absolute;
  top: 33.26%;
  width: 100%;
}
.line-2-2 {
  bottom: 33.26%;
  height: 1px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.line-2-3 {
  bottom: 0px;
  height: 1px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.line-3 {
  bottom: 68px;
  height: 1px;
  left: 84px;
  position: absolute;
  width: calc(100.00% - 168px);
}
.line-4 {
  bottom: 30.79%;
  height: 1px;
  left: 84px;
  position: absolute;
  width: calc(100.00% - 168px);
}
.line-6 {
  height: 1px;
  left: 84px;
  position: absolute;
  top: 25.54%;
  width: calc(100.00% - 168px);
}
.line-7 {
  height: 1px;
  left: 84px;
  position: absolute;
  top: 40.10%;
  width: calc(100.00% - 168px);
}
.line-8 {
  bottom: 45.35%;
  height: 1px;
  left: 84px;
  position: absolute;
  width: calc(100.00% - 168px);
}
.line-chart2 {
  height: 16px;
  left: calc(21.51% - 4px);
  position: absolute;
  top: 141px;
  width: 18px;
}
.lines {
  bottom: calc(36.82% - 88px);
  height: 240px;
  left: 120px;
  position: absolute;
  width: calc(100.00% - 207px);
}
.list-text2 {
  height: 12px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 18px;
}
.mask-group-12 {
  height: 920px;
  left: 422px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 148px;
  width: 283px;
}
.mask-group-2 {
  height: 231px;
  position: absolute;
  right: calc(50.00% - 115px);
  top: calc(40.52% - 94px);
  width: 231px;
}
.may-2019 {
  color: var(--eerie-black);
  font-family: 'Axiforma-SemiBold', Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 18px;
  left: 64.21%;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  top: 5.88%;
  white-space: nowrap;
  width: 31.48%;
}
.nov-2018 {
  height: 21px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(24.75% - 22px);
  text-align: center;
  top: 0px;
  width: 90px;
}
.nov-2018-1 {
  bottom: 30px;
  height: 21px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(27.00% - 24px);
  text-align: center;
  width: 90px;
}
.number-12 {
  height: 14px;
  left: 0px;
  letter-spacing: 0.12px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: calc(31.09% - 4px);
  white-space: nowrap;
  width: calc(100.00% - 1px);
}
.number-22 {
  bottom: calc(35.29% - 5px);
  height: 14px;
  letter-spacing: 0.12px;
  line-height: 24px;
  position: absolute;
  right: calc(42.86% - 3px);
  text-align: center;
  white-space: nowrap;
  width: 8px;
}
.number-32 {
  bottom: 4px;
  height: 14px;
  left: calc(40.00% - 4px);
  letter-spacing: 0.12px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 10px;
}
.number-42 {
  color: var(--blue);
  font-family: 'Axiforma-Regular', Helvetica;
  font-size: 71px;
  left: 904px;
  letter-spacing: 0.00px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 704px;
  white-space: nowrap;
}
.number-52 {
  color: var(--blue);
  font-family: 'Axiforma-Regular', Helvetica;
  font-size: 71px;
  left: 1229px;
  letter-spacing: 0.00px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 704px;
  white-space: nowrap;
}
.number2 {
  height: 14px;
  left: 1px;
  letter-spacing: 0.12px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: -6px;
  white-space: nowrap;
  width: calc(100.00% + 1px);
}
.oct-2018 {
  height: 21px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(49.83% - 45px);
  text-align: center;
  top: 0px;
  width: 90px;
}
.oct-2018-1 {
  bottom: 30px;
  height: 21px;
  left: calc(27.30% - 25px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  width: 90px;
}
.path-1-1 {
  height: calc(100.00% + 1px);
  left: -1px;
  position: absolute;
  top: -1px;
  width: calc(100.00% + 1px);
}
.path-1-2 {
  height: calc(100.00% + 1px);
  left: 0px;
  position: absolute;
  top: -1px;
  width: calc(100.00% + 1px);
}
.path-112 {
  height: calc(100.00% + 1px);
  left: -1px;
  position: absolute;
  top: 0px;
  width: calc(100.00% + 1px);
}
.path-15 {
  height: calc(100.00% + 1px);
  left: 0px;
  position: absolute;
  top: 0px;
  width: calc(100.00% + 1px);
}
.path-425 {
  height: 146px;
  position: absolute;
  right: -94px;
  top: -6px;
  width: 226px;
}
.path-426 {
  height: calc(100.00% + 82px);
  left: -59px;
  position: absolute;
  top: -41px;
  width: calc(100.00% + 161px);
}
.path-427 {
  height: 159px;
  left: calc(29.16% - 30px);
  position: absolute;
  top: -45px;
  width: 102px;
}
.path-428 {
  height: 210px;
  left: 0px;
  position: absolute;
  top: -1px;
  width: calc(100.00% - 7px);
}
.path-429 {
  bottom: 0px;
  height: 16px;
  left: 0px;
  position: absolute;
  width: 31px;
}
.percent-12 {
  height: 18px;
  left: calc(46.22% - 12px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: calc(27.83% - 5px);
  white-space: nowrap;
  width: 26px;
}
.percent-22 {
  height: 18px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(35.29% - 12px);
  text-align: center;
  top: calc(32.24% - 6px);
  white-space: nowrap;
  width: 34px;
}
.percent-32 {
  bottom: calc(30.93% - 6px);
  height: 18px;
  left: calc(24.22% - 8px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 32px;
}
.percent-42 {
  bottom: 18px;
  height: 18px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(45.47% - 14px);
  text-align: center;
  white-space: nowrap;
  width: 30px;
}
.percent-52 {
  bottom: 10px;
  height: 18px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(45.44% - 12px);
  text-align: center;
  white-space: nowrap;
  width: 26px;
}
.percent-62 {
  bottom: calc(44.12% - 8px);
  height: 18px;
  left: calc(22.18% - 8px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 34px;
}
.percent-72 {
  height: 18px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(43.17% - 15px);
  text-align: center;
  top: 16px;
  white-space: nowrap;
  width: 34px;
}
.percent-82 {
  height: 18px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  right: calc(44.34% - 15px);
  text-align: center;
  top: 15px;
  white-space: nowrap;
  width: 34px;
}
.percent2 {
  bottom: calc(42.42% - 8px);
  height: 18px;
  left: calc(43.81% - 15px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 34px;
}

.rectangle-2952 {
  -webkit-backdrop-filter: blur(30px) brightness(104%);
  backdrop-filter: blur(30px) brightness(104%);
  border-radius: 41px;
  bottom: 68px;
  height: 920px;
  left: 18px;
  position: absolute;
  width: calc(100.00% - 54px);
}
.rectangle-2982 {
  -webkit-backdrop-filter: blur(25px) brightness(104%);
  backdrop-filter: blur(25px) brightness(104%);
  border-radius: 41px;
  height: 920px;
  left: 785px;
  opacity: 0;
  position: absolute;
  top: 148px;
  width: 1397px;
}
.rectangle-3 {
  height: 8px;
  position: absolute;
  right: 1px;
  top: calc(39.05% - 3px);
  width: 8px;
}
.rectangle-305 {
  -webkit-backdrop-filter: blur(25px) brightness(105%);
  backdrop-filter: blur(25px) brightness(105%);
  border-radius: 41px;
  height: 190px;
  left: 785px;
  position: absolute;
  top: 648px;
  width: 280px;
}
.rectangle-492 {
  background-color: var(--blue-jeans);
  border-radius: 12px;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 31px;
}
.rectangle-493 {
  background-color: var(--eucalyptus);
  border-radius: 12px;
  height: 16px;
  left: 0px;
  position: absolute;
  top: calc(48.08% - 8px);
  width: 31px;
}
.rectangle-494 {
  background-color: var(--blue-jeans);
  border-radius: 25px;
  height: 126.52%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100.00%;
}
.rectangle-495 {
  background-color: var(--blue-jeans);
  height: 32.11%;
  left: 0px;
  position: absolute;
  top: 100.00%;
  width: 100.00%;
}
.rectangle-496 {
  background-color: var(--eucalyptus);
  border-radius: 25px;
  height: 120.47%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100.00%;
}
.rectangle-497 {
  background-color: var(--eucalyptus);
  height: 24.78%;
  left: 0px;
  position: absolute;
  top: 100.00%;
  width: 100.00%;
}
.rectangle-498 {
  background-color: var(--scarpa-flow);
  border-radius: 25px;
  height: 208.30%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100.00%;
}
.rectangle-499 {
  background-color: var(--scarpa-flow);
  height: 136.70%;
  left: 0px;
  position: absolute;
  top: 100.00%;
  width: 100.00%;
}
.rectangle-500 {
  background-color: var(--scarpa-flow);
  border-radius: 25px;
  height: 164.54%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100.00%;
}
.rectangle-501 {
  background-color: var(--scarpa-flow);
  height: 78.13%;
  left: 0px;
  position: absolute;
  top: 100.00%;
  width: 100.00%;
}
.rectangle-502 {
  background-color: var(--eucalyptus);
  border-radius: 25px;
  height: 133.67%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100.00%;
}
.rectangle-503 {
  background-color: var(--eucalyptus);
  height: 40.76%;
  left: 0px;
  position: absolute;
  top: 100.00%;
  width: 100.00%;
}
.rectangle-504 {
  background-color: var(--blue-jeans);
  border-radius: 25px;
  height: 123.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100.00%;
}
.rectangle-505 {
  background-color: var(--blue-jeans);
  height: 28.11%;
  left: 0px;
  position: absolute;
  top: 100.00%;
  width: 100.00%;
}
.rectangle-506 {
  -webkit-backdrop-filter: blur(25px) brightness(105%);
  backdrop-filter: blur(25px) brightness(105%);
  border-radius: 41px;
  height: 190px;
  left: 1109px;
  position: absolute;
  top: 648px;
  width: 280px;
}
.select-dropdown {
  height: 8px;
  left: calc(46.63% - 4px);
  position: absolute;
  top: calc(39.05% - 3px);
  width: 8px;
}
.sep-2018 {
  height: 21px;
  left: calc(25.08% - 23px);
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 90px;
}
.subtraction-1 {
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.subtraction-2 {
  bottom: 0px;
  height: 128px;
  left: 0px;
  position: absolute;
  width: calc(100.00% - 25px);
}
.subtraction-3 {
  bottom: 0px;
  height: 40px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.subtraction-4 {
  bottom: 1px;
  height: 49px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.subtraction-5 {
  bottom: 1px;
  height: 83px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.subtraction-6 {
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.tat-des-services {
  height: 27px;
  left: 50px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  top: 46px;
  white-space: nowrap;
  width: 177px;
}
.text-1 {
  height: 27px;
  left: 44px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
  width: 298px;
}
.text-2 {
  height: 19px;
  left: 86px;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: calc(21.35% - 4px);
  white-space: nowrap;
  width: 292px;
}
.text-3 {
  height: 27px;
  left: 45px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
  width: 242px;
}
.text-4 {
  color: var(--eerie-black);
  font-family: 'Axiforma-Regular', Helvetica;
  font-size: 20px;
  left: 827px;
  letter-spacing: 0.00px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 757px;
  white-space: nowrap;
}
.text-5 {
  color: var(--eerie-black);
  font-family: 'Axiforma-Regular', Helvetica;
  font-size: 20px;
  left: 1129px;
  letter-spacing: 0.00px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 757px;
  width: 242px;
}
.to {
  height: 19px;
  left: 57.21%;
  letter-spacing: 0.00px;
  line-height: 16px;
  position: absolute;
  top: 2.94%;
  white-space: nowrap;
  width: 5.68%;
}
.y-axis {
  bottom: calc(35.34% - 89px);
  height: 252px;
  left: 88px;
  position: absolute;
  width: 13px;
}

.log-out {
  /*width: 90%;*/
  /*margin-left: 20%;*/
  margin-bottom: 15%;
  margin-top: 10%;
  border: 0;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  background: transparent;
  text-align: start;
  cursor: pointer;
}

.log-out:hover {
  background: var(--transparent-blue);
}

.item-table {
  -webkit-flex: 4 1;
          flex: 4 1;
  height: 100%;
  padding: 3%;
  overflow: scroll;
}

.item-info {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%;
  padding: 3%;
  overflow: scroll;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 3%;
}

.widgets {
  left: 24%;
  top: 5%;
  width: 71%;
  height: 90%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  position: fixed;
}

.widget-title {
  width: 100%;
}

.widget-title-2 {
  width: 100%;
  margin-bottom: 15px;
}

.hover-buttons {
  bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
}

.white-button {
  cursor: pointer;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.102);
  border-radius: 20px;
  background: white;
  padding: 10px;
  margin-right: 15px;
}

.white-button:hover {
  background: var(--transparent-blue);
}

.white-button-icon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

/* Chrome 29+ */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .add-button {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

/* Firefox */
@-moz-document url-prefix() {
  .add-button {
    position: sticky;
    bottom: 0;
  }
}

.max-width {
  width: 100%;
}

.qr-code {
  display: none;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@page {
  size: auto;
  margin: 0 0 0 0;
}

@media print {
  .qr-code {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.field-title-container {
  display: -webkit-flex;
  display: flex;
}

.field-title {
  margin-bottom: 5px;
  margin-left: 2px;
}

.field-text {
  padding-left: 10px;
  width: 100%;
}

.comments {
  padding: 12px;
}

.field-input {
  border-radius: 15px;
  border: 1px solid transparent;
  padding: 6px 6px 6px 12px;
  background: var(--transparent-white);
  width: 100%;
}

.field-input:hover {
  border: 1px solid transparent;
  border-color: var(--blue);
}

/**
 * Remove border color on focus
 */
.field-input:focus {
  border: 1px solid transparent;
}

.edit-row {
  margin-top: 20px;
}

.button-wrapper {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.margin-top {
  margin-top: 20px;
}

.missing-fields-error {
  margin-top: 20px;
  margin-bottom: -20px;
}

.cancel-button {
  cursor: pointer;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 2rem;
}

.cancel-button:hover {
  background: var(--transparent-blue);
}

.popup-buttons {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

textarea {
  resize: none;
}

.print-button {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  border-radius: 20px;
  border: 2px solid var(--blue);
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.print-button:hover {
  background: var(--transparent-blue);
}

.Toastify__toast {
  border-radius: 150px;
  padding: 0 25px;
  -webkit-align-items: center;
          align-items: center;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
  -webkit-flex: none;
          flex: none;
}

.Toastify__close-button {
  -webkit-align-self: center;
          align-self: center;
}

.Toastify__toast-container {
  width: 400px;
}

.Toastify__toast--error {
  background: var(--error-red);
}

@page :left {
  margin-left: 0;
}

@page :right {
  margin-right: 0;
}

/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  font-family: 'Axiforma-Regular', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 6px 6px 6px 12px;
  background: var(--transparent-white);
  border: 1px solid transparent;
  border-radius: 15px;
  outline: none;
  font-family: 'Axiforma-Regular', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: auto;
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Axiforma-Regular', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: var(--blue);
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: var(--transparent-blue);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: var(--blue);
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-family: 'Axiforma-Light';
  font-size: 14px;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: auto;
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: var(--blue);
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 15px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  -webkit-animation: load 1s infinite linear;
          animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.loading-button {
  padding: 0.5rem 2rem;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  border-width: 0;
  margin-top: 40px;
  background-color: var(--blue);
  border-radius: 200px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.102);
  cursor: pointer;
}

.loading-button > div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

.center-login {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
}

.login-logo {
  width: 200px;
  margin-bottom: 50px;
}

.login-error {
  margin-top: 25px;
}

.search-bar {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 85px;
}

.search-mask {
  border-radius: 39px;
  height: 77px;
  overflow: hidden;
  position: absolute;
}

.search-shadow {
  border-radius: 39px;
  -webkit-filter: blur(7px);
          filter: blur(7px);
  border: 15px solid var(--darker-shadow);
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
}

.search-light {
  border-radius: 39px;
  -webkit-filter: blur(7px);
          filter: blur(7px);
  border: 15px solid var(--lighter-light);
  height: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.search-blur {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  border-radius: 39px;
  height: 90px;
  position: absolute;
}

.search-text {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background: transparent;
  border: none;
  outline-color: transparent;
  outline-style: none;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--half-blue);
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--half-blue);
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--half-blue);
}

.button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 37px;
  height: 75px;
  width: 180px;
  cursor: pointer;
}

.button-pressed {
  background: linear-gradient(
    157.37deg,
    var(--darker-shadow) 13.29%,
    var(--lighter-light) 84.76%
  );
  border-radius: 37px;
  -webkit-filter: blur(6px);
          filter: blur(6px);
  position: absolute;
  height: 75px;
  width: 180px;
  will-change: opacity;
}

.button-shadow {
  box-shadow: 10px 10px 10px var(--darker-shadow);
  border-radius: 37px;
  -webkit-filter: blur(4px);
          filter: blur(4px);
  position: absolute;
  height: 75px;
  width: 180px;
  will-change: opacity;
}

.button-light {
  background: linear-gradient(
    157.37deg,
    var(--light) 13.29%,
    var(--shadow) 84.76%
  );
  box-shadow: -10px -10px 10px var(--lighter-light);
  border-radius: 37px;
  -webkit-filter: blur(4px);
          filter: blur(4px);
  position: absolute;
  height: 75px;
  width: 180px;
  will-change: opacity;
}

.button-text {
  position: absolute;
  will-change: transform;
  text-align: center;
}

.password {
  -webkit-text-security: disc;
  text-security: disc;
}

