.item-table {
  flex: 4;
  height: 100%;
  padding: 3%;
  overflow: scroll;
}

.item-info {
  flex: 1;
  height: 100%;
  padding: 3%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3%;
}

.widgets {
  left: 24%;
  top: 5%;
  width: 71%;
  height: 90%;
  justify-content: space-between;
  display: flex;
  position: fixed;
}

.widget-title {
  width: 100%;
}

.widget-title-2 {
  width: 100%;
  margin-bottom: 15px;
}

.hover-buttons {
  bottom: 0;
  position: sticky;
  z-index: 999;
  display: flex;
}

.white-button {
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.102);
  border-radius: 20px;
  background: white;
  padding: 10px;
  margin-right: 15px;
}

.white-button:hover {
  background: var(--transparent-blue);
}

.white-button-icon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

/* Chrome 29+ */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .add-button {
    position: sticky;
    bottom: 0;
  }
}

/* Firefox */
@-moz-document url-prefix() {
  .add-button {
    position: sticky;
    bottom: 0;
  }
}
