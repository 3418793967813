@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.loading-button {
  padding: 0.5rem 2rem;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  border-width: 0;
  margin-top: 40px;
  background-color: var(--blue);
  border-radius: 200px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.102);
  cursor: pointer;
}

.loading-button > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
